<template>
  <div>
    <div class="main-header">
      <div class="title">风采墙管理</div>
    </div>
    <div class="main-main">
      <a-card>
        <div class="optionsAndPagination"></div>
        <div class="actions">
          <div class="btns" style="gap: 10px"></div>
          <Pagination
            @refresh="refresh"
            @pageChange="pageChange"
            @pageSizeChange="pageSizeChange"
            :total="total"
            :page="searchOptions.page"
          ></Pagination>
        </div>
        <div>
          <a-table
            size="middle"
            :loading="tableLoading"
            :data-source="tableData"
            :columns="tableColumns"
            :pagination="false"
            :scroll="{ x: 10 }"
          >
            <p slot="expandedRowRender" slot-scope="row" style="margin: 0">
              <detail
                :text="row.content"
                :imgs="row.picList"
                :id="row.elegantId"
              ></detail>
            </p>
            <template slot="userImage" slot-scope="userImage">
              <img :src="userImage" alt="" class="userImage" />
            </template>
            <template slot="userName" slot-scope="userName">
              {{ userName }}
            </template>
            <template slot="addTime" slot-scope="addTime">
              {{ addTime }}
            </template>
            <template slot="action" slot-scope="row">
              <div style="display: flex; gap: 10px; justify-content: center">
                <a @click="showLikeList(row)">点赞列表</a>
                <a @click="delMoment(row)">删除</a>
              </div>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>
    <a-modal
      v-model="likeTable"
      :footer="null"
      :closable="false"
      title="点赞列表"
    >
      <a-table
        :data-source="likeTableData"
        :columns="likeTableColumns"
        :pagination="false"
        :scroll="{ y: 400 }"
      >
        <template slot="userImage" slot-scope="userImage">
          <img :src="userImage" alt="" class="userImage" />
        </template>
        <template slot="addTime" slot-scope="addTime">
          <span>{{ $dayjs(addTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import { getMomentList,delMoment } from "@/network/api";
import Pagination from "../components/Pagination";
import { mapState } from "vuex";
import detail from "../../components/detail.vue";
export default {
  components: {
    Pagination,
    detail,
  },
  data() {
    return {
      now: new Date().valueOf(),
      form: {},
      total: 0,

      tableLoading: true,
      tableData: [],
      searchOptions: {
        page: 1,
        pageSize: 20,
        activityId:9999
      },
      allUserType: [
        { title: "长者", value: 1 },
        { title: "志愿者", value: 2 },
        { title: "管理员", value: 3 },
      ],
      likeTableData: [],
      likeTable: false,
    };
  },
  methods: {

    showLikeList(i) {
 this.likeTableData = i.likeList;
     
      this.likeTable = true;
    },
    formatTime(val) {
      if (this.now - val > 90000000) {
        return this.$dayjs(val - 0).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return this.$dayjs().to(this.$dayjs(val - 0));
      }
    },

    delMoment(row) {
      var params = {
        elegantId: row.elegantId,
      };
      this.$confirm({
        title: `确定要删除该风采吗？`,
        onOk: () => {
          return new Promise((resolve, reject) => {
            delMoment(params).then((res) => {
              if (res.status == 0) {
                this.getMomentList();
                this.$message.success(`删除成功！`);
                resolve();
              } 
            });
          }).catch(() => {
            this.$message.error(`删除失败！`);
          });
        },
      });
    },
    async getMomentList(
      params = {
        page: 1,
        pageSize: 20,
        activityId:9999
      }
    ) {
      this.tableLoading = true;
      const { data } = await getMomentList(params);
      this.total = data.total
      this.tableData = data.content.map(item=>{
        return item
      })
      this.tableLoading = false;
    },

    pageChange(page, pageSize) {
      this.searchOptions.page = page;
      this.getMomentList(this.searchOptions);
    },
    pageSizeChange(page, pageSize) {
      this.searchOptions.page = 1;
      this.searchOptions.pageSize = pageSize;
      this.getMomentList(this.searchOptions);
    },
    refresh() {
      this.getMomentList();
    },
  },

  created() {
    this.getMomentList();
  },
  computed: {
    likeTableColumns() {
      return [
        {
          dataIndex: "userImage",
          title: "",
          align: "center",
          width: 40,
          scopedSlots: { customRender: "userImage" },
        },
        {
          dataIndex: "userName",
          title: "昵称",
          align: "center",
          width: 80,
        },
        {
          dataIndex: "addTime",
          title: "点赞时间",
          align: "center",
          scopedSlots: { customRender: "addTime" },
        },
      ];
    },

    tableColumns() {
      return [
        {
          dataIndex: "userImage",
          title: "头像",
          align: "center",
          scopedSlots: { customRender: "userImage" },
          width: 80,
        },
        {
          dataIndex: "userName",
          title: "昵称",
          align: "center",
          scopedSlots: { customRender: "userName" },
          width: 80,
        },
        {
          dataIndex: "addTime",
          title: "发布时间",
          align: "center",
          scopedSlots: { customRender: "addTime" },
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 300,
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 22px;
}
.date-picker {
  display: flex;
  align-items: center;
}

.ant-card-body {
  padding: 0.56rem 1.06rem;
}
.ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tab {
  border-radius: none !important;
}

.ant-table-row-cell-break-word {
  text-align: center !important;
}

.pagination {
  display: flex;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.actions .label {
  display: flex;
  align-items: center;
}
.actions .btns {
  display: flex;
  justify-content: space-between;
}
.excel-btn {
  padding: 0 !important;
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
  vertical-align: middle;
}
.excel-btn:after {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.ant-tabs-tab {
  margin-right: 0;
}

.search-card {
  margin-bottom: 10px;
}
.disabled {
  color: #c3c3c3;
  cursor: not-allowed;
}
.userImage {
  border: 1px #eee solid;
  width: 30px;
  height: 30px;
  border-radius: 50px;
}
</style>