<template>
  <div>
    <div>{{ text }}</div>
    <div class="gird-pic">
      <div class="pic-container" v-for="(item, index) in imgs" :key="index">
        <img :src="item" :preview="id"/>
      </div>
    </div>
    <!-- <div
      class="over-lay"
      @click="
        () => {
          this.showImg = false;
        }
      "
      v-show="showImg"
    >
      <img :src="selectedImg" alt="" />
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedImg: "",
      showImg: false,
    };
  },
  methods: {
    selectImg(item) {
      this.showImg = true;
      this.selectedImg = item;
    },
  },
  props: {
    text: String,
    imgs: Array,
    id:{}
  },
};
</script>

<style scoped lang="less">
.over-lay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000bf;
  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.gird-pic {
  display: flex;
  flex-wrap: wrap;
  .pic-container {
    display: flex;
    align-items: center;
    width: 120px;
    height: 120px;
    border: 1px solid #f1f1f1;
    margin-right: 10px;
    margin-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
</style>